import { Button, TextField } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import * as XLSX from 'xlsx';
import firebase from 'firebase'
import { saveAs } from 'file-saver';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";

export default function BatchUpload() {
    const [fileUpdate, setFileUpdate] = useState(null);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [addrLine1, setAddrLine1] = useState('');
    const [addrLine2, setAddrLine2] = useState('');
    const [addrCity, setAddrCity] = useState('');
    const [addrState, setAddrState] = useState('');
    const [addrZip, setAddrZip] = useState('');
	const user = useContext(UserContext);
    const [uploadRentalsList, setUploadRentalList] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();

        if (fileExtension === 'xlsx') {
            setFile(selectedFile);
            setMessage('');
        } else {
            setFile(null);
            alert('Please select an Excel file with .xlsx extension.');
        }
    };
    const handleSubmit2 = async (e) => {

        const uploadExcel = firebase.functions().httpsCallable('batchUploadExcelSpreadsheet');
            uploadExcel({ data: uploadRentalsList })
                .then((result) => {
                    console.log(result)
                    alert('File uploaded and processed successfully.');
                })
                .catch((error) => {
                    console.log(error)
                    setMessage(`Error: ${error.message}`);
                });
        console.log("Submitting")

        uploadExcel()

    }



    const handleSubmit = async (e) => {
            e.preventDefault();
            if (!file) {
                alert('Please select a valid Excel file to upload.');
                return;
            }
    
            const formData = new FormData();
            formData.append('file', file);
    
            // Read and log the Excel file data
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                // Get the range of the worksheet
                const range = XLSX.utils.decode_range(sheet['!ref']);
                const headerRange = { s: { r: 0, c: range.s.c }, e: { r: 0, c: range.e.c } };

                // Modify header values
                for (let colIndex = headerRange.s.c; colIndex <= headerRange.e.c; colIndex++) {
                    const address = XLSX.utils.encode_cell({ r: headerRange.s.r, c: colIndex });
                    const headerCellValue = sheet[address].v;

                    // Example: Replace 'Name' with 'Full Name'
                    if (headerCellValue === 'Rental Title') {
                        sheet[address].v = 'rentalTitle';
                    }
                    if (headerCellValue === 'Rental Description') {
                        sheet[address].v = 'rentalDesc';
                    }
                    if (headerCellValue === 'Category') {
                        sheet[address].v = 'category';
                    }
                    if (headerCellValue === 'Start Date - (YYYY-MM-DD') {
                        sheet[address].v = 'startAvailable';
                    }
                    if (headerCellValue === 'Pickup Start - (HH:MM PM)') {
                        sheet[address].v = 'preferredpickup_start';
                    }
                    if (headerCellValue === 'Day Fee') {
                        sheet[address].v = 'fee_d';
                    }
                    if (headerCellValue === 'Half Day Fee') {
                        sheet[address].v = 'fee_h';
                    }
                    if (headerCellValue === 'Week Fee') {
                        sheet[address].v = 'fee_w';
                    }
                    if (headerCellValue === 'Main Image URL') {
                        sheet[address].v = 'mainImage';
                    }
                    if (headerCellValue === 'First Name ') {
                        sheet[address].v = 'fname';
                    }
                    if (headerCellValue === 'Last Name') {
                        sheet[address].v = 'lname';
                    }
                    if (headerCellValue === 'Buisness Name') {
                        sheet[address].v = 'businessName';
                    }
                    if (headerCellValue === 'Insurance Required') {
                        sheet[address].v = 'insuranceReq';
                    }
                    if (headerCellValue === 'Paper Work Required') {
                        sheet[address].v = 'paperworkReq';
                    }
                    if (headerCellValue === 'Pickup Address') {
                        sheet[address].v = 'pickupAddress1';
                    }
                    if (headerCellValue === 'Pickup City') {
                        sheet[address].v = 'pickupAddressCity';
                    }
                    if (headerCellValue === 'Pickup State') {
                        sheet[address].v = 'pickupAddressState';
                    }
                    if (headerCellValue === 'Pickup Zip') {
                        sheet[address].v = 'pickupAddressZip';
                    }
                    if (headerCellValue === 'Pickup Instructions') {
                        sheet[address].v = 'pickupInst';
                    }
                    if (headerCellValue === 'Dropoff Address') {
                        sheet[address].v = 'dropoffAddress1';
                    }
                    if (headerCellValue === 'Dropoff City') {
                        sheet[address].v = 'dropoffAddressCity';
                    }
                    if (headerCellValue === 'Dropoff State') {
                        sheet[address].v = 'dropoffAddressState';
                    }
                    if (headerCellValue === 'Dropoff Zip') {
                        sheet[address].v = 'dropoffAddressZip';
                    }
                    if (headerCellValue === 'Dropoff Instructions') {
                        sheet[address].v = 'dropoffInst';
                    }
                    

                    // Add more if conditions to replace other header values as needed
                }

                // Convert sheet to JSON with modified headers
                const jsonData = [];
                for (let rowIndex = headerRange.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
                    const row = {};
                    for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
                        const headerAddress = XLSX.utils.encode_cell({ r: headerRange.s.r, c: colIndex });
                        const header = sheet[headerAddress].v;
                        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                        const cellValue = sheet[cellAddress] ? sheet[cellAddress].v : null;
                        const cellRes = validateHeaderAndCell(header,cellValue)
                        if(cellRes == false){
                            alert("Please Fix Cell Error row: " + rowIndex + " column: " + header)
                            return 
                        }
                        row[header] = cellValue;
                    }
                    row['useremail'] = user.email
                    row['owner'] = user.uid
                    row['ownerPhoto'] = user.photoURL
                    jsonData.push(row);
                }

                // Print JSON data
                console.log(jsonData);
                setUploadRentalList(jsonData)
            };
            reader.readAsArrayBuffer(file);
 };

 function validateHeaderAndCell(header,cellValue){
    if(header == "rentalTitle"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "useremail"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "rentalDesc"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "preferredpickup_start"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "pickupInst"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "pickupAddressZip"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "pickupAddressState"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "pickupAddressCity"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "pickupAddress1"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "mainImage"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "lname"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "fname"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "fee_w"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "fee_h"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "fee_d"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "dropoffInst"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "dropoffAddressZip"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "dropoffAddressState"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "dropoffAddressCity"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "dropoffAddress1"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "businessName"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "Pickup End"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "category"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }
    if(header == "Required"){
        if(cellValue == ""|| cellValue == undefined){
            alert("Rental Title Incorrect.")
            return false
        }
    }

 }
    

    function downloadSample(){
      // URL of the CSV file

      var link = document.createElement("a");
      link.setAttribute("href", "https://firebasestorage.googleapis.com/v0/b/renteasedev.appspot.com/o/RentalUploadTemplate.xlsx?alt=media&token=78f12a9d-7b69-4c2e-9298-1a5c52a9ffcd");
      link.setAttribute("download", "RentalDataSpreedsheet.xlsx");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      document.body.removeChild(link);

    }

    function downloadSampleCSV(){
        // URL of the CSV file
        var storageBucket = "YOUR_STORAGE_BUCKET";
        var filePath = "path/to/your/file.csv";
  
        // Generate the download URL
        var downloadURL = "https://firebasestorage.googleapis.com/v0/b/renteasedev.appspot.com/o/Rental_Template1.csv?alt=media&token=578d64e5-3c77-4fbf-a375-3a838895fde0"+ "?alt=media";
  
        // Create an anchor element
        var link = document.createElement("a");
        link.setAttribute("href", downloadURL);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
  
        // Trigger the download
        link.click();
  
        // Cleanup
        document.body.removeChild(link);
      }

    const handleDownload = async () => {
        const generateExcel = firebase.functions().httpsCallable('generateMyRentalExcel');
        try {
          const result = await generateExcel();
          const base64String = result.data.file;
          const binaryString = Buffer.from(base64String, 'base64');
          const blob = new Blob([binaryString], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'data.xlsx');
        } catch (error) {
          console.error('Error generating spreadsheet:', error);
        }
      };

      const handleFileChangeUpdate = (e) => {
        const selectedFile = e.target.files[0];
        const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();

        if (fileExtension === 'xlsx') {
            setFileUpdate(selectedFile);
            setMessage('');
        } else {
            setFileUpdate(null);
            alert('Please select an Excel file with .xlsx extension.');
        }
    };

    const handleSubmitUpdate = async (e) => {
            e.preventDefault();
            if (!fileUpdate) {
                alert('Please select a valid Excel file to upload.');
                return;
            }
    
            const formData = new FormData();
            formData.append('file', fileUpdate);
    
            // Read and log the Excel file data
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
    
                // Log each row of data to the console
                jsonData.forEach((row, index) => {
                    console.log(`Row ${index + 1}:`, row);
                });
    
                const uploadExcel = firebase.functions().httpsCallable('batchUploadExcelSpreadsheetUpdate');
                uploadExcel({ data: jsonData })
                    .then((result) => {
                        console.log(result)
                        setMessage('File uploaded and processed successfully.');
                    })
                    .catch((error) => {
                        console.log(error)
                        setMessage(`Error: ${error.message}`);
                    });
            };
            reader.readAsArrayBuffer(file);
 };
    

    return(
        <div>          
            <div style={{margin:40,textAlign:'center',fontSize:'large',fontWeight:'bolder'}}>
                Batch Upload Items
            </div>
            <div style={{margin:40,textAlign:'center'}}>
Uploading rentals using an Excel spreadsheet simplifies the process of listing multiple properties efficiently. By organizing rental information such as property details, amenities, and rental rates in a spreadsheet, it becomes easier to manage and update. Additionally, you can enhance your listings by adding your own hosted images directly to the spreadsheet. This allows you to create visually appealing listings that attract potential renters, while also keeping all necessary information neatly organized in one place.
            </div>
            <hr/>
            <div style={{margin:30,textAlign:'center'}}>
                Please select type: 
            </div>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <button class="btn btn--primary btn--medium null" onClick={()=>{downloadSample()}}>Download Sample Excel Sheet</button>
            </div>
            {/*<div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <button class="btn btn--primary btn--medium null" onClick={()=>{downloadSampleCSV()}}>Download Sample CSV Sheet</button>
            </div>*/}
            <hr/>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <div style={{margin:20,fontSize:'large'}}>Upload New Rental Spreadsheet</div>
            {/*<div style={{margin:20,fontSize:'small'}}>The following address will be added to all rentals in the spreadsheet.</<div>
            <div style={{justifyContent:'center',maxWidth:400,margin:'auto'}}>
                <div>
                <div>Address Line 1</div>
            <TextField
                    placeholder="Address line 1"
                    helperText="An email will be sent with next information."
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={addrLine1}
                    onChange={(val)=>{setAddrLine1(val.value)}}
                    variant="outlined"
                    />
                    </div>
                    <div>
                <div>Address Line 2</div>
            <TextField
                    placeholder="Address line 2"
                    helperText="An email will be sent with next information."
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={addrLine2}
                    onChange={(val)=>{setAddrLine2(val.value)}}
                    variant="outlined"
                    />
                    </div>
                    <div>
                <div>Address Line City</div>
            <TextField
                    placeholder="Address City"
                    helperText="An email will be sent with next information."
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={addrCity}
                    onChange={(val)=>{setAddrCity(val.value)}}
                    variant="outlined"
                    />
                    </div>
                    <div>
                <div>Address State</div>
            <TextField
                    placeholder="Address line 1"
                    helperText="An email will be sent with next information."
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={addrState}
                    onChange={(val)=>{setAddrState(val.value)}}
                    variant="outlined"
                    />
                    </div>
                    <div>
                <div>Address Zipcode</div>
            <TextField
                    placeholder="Address Zipcode"
                    helperText="An email will be sent with next information."
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={addrZip}
                    onChange={(val)=>{setAddrZip(val.value)}}
                    variant="outlined"
                    />
                    </div>
                </div>*/}
            <div style={{margin:20,fontSize:'small'}}>Upload your correct spreadsheet by choosing file below and click below to upload all rentals.</div>
            <br/>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} /><br/><br/>
                <button class="btn btn--primary btn--medium null" type="submit">Confirm Spreadsheet (xlsx)</button>
            </form>
            <br/>
            <br/>
            <div>
                <div>
                    Upload Rental Row Values
                </div>
                <div style={{textAlign:'center'}}>
                <div style={{marginBottom:80}}>
                            <table style={{width:'100%'}}>
                                <tr>
                                    <th>Title</th>
                                    <th>State Date</th>
                                    <th>Day Fee</th>
                                    <th>Half Day Fee</th>
                                    <th>Week Fee</th>
                                </tr>
                {uploadRentalsList.map((item,index)=>{
                    return(
                        <tr>
                            <td>
                                {item.rentalTitle}
                            </td>
                            <td>
                                {item.startAvailable}
                            </td>
                            <td>
                                {item.fee_d}
                            </td>
                            <td>
                                {item.fee_h}
                            </td>
                            <td>
                                {item.fee_w}
                            </td>
                        </tr>
                                

                    )
                })}
                </table>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <br/>
            <div>
            <button class="btn btn--primary btn--medium null" onClick={()=>{
                handleSubmit2()
            }}>Complete Upload Spreadsheet (xlsx)</button>
            </div>
            </div>
            <hr/>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <button class="btn btn--primary btn--medium null" onClick={()=>{handleDownload()}}>Export My Rental Spredsheet</button>
            </div>
            <hr/>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <div style={{margin:20,fontSize:'large'}}>Update Current Rental Spreadsheet</div>
            <div style={{margin:10,fontSize:'small'}}>Only Include Current Rentals, if you would like to add a new rental use add.</div>
            <form onSubmit={handleSubmitUpdate}>
                <input type="file" onChange={handleFileChangeUpdate} /><br/><br/>
                <button class="btn btn--primary btn--medium null" type="submit">Update (only) Included rentals (xlsx)</button>
            </form>
            </div>
            <hr/>
            <hr/>
        </div>
    )

}