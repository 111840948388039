import { Button } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import React from 'react'

export default function Marketing1(){
    React.useEffect(() => {
        window.scrollTo(0, 0)
        }, [])
    return (
        <div>
            <div className='fb sb' style={{marginBottom:50}}>
                <div></div>
                <div >
                    <div style={{fontFamily:'serif',fontSize:50,color:'#0F79A7'}}>
                        Experience More:<br/>
                        Rent. <span style={{fontWeight:'bolder',fontStyle:'italic'}}>Enjoy.</span> <ArrowForward className='buttonArrow'/> <Button style={{background:'#D4F2FF',borderRadius:59,width:230,height:50,marginLeft:60,border:'2px solid #427593'}}>Search Categories</Button><br/>
                        Return.
                    </div>
                </div>
                <div></div>
            </div>
            <div style={{marginBottom:50}}>
                <div>
                    <div className='fb se fw'>
                        <Button className='marketingButtonCategory'>Dorm Essentials <ArrowForward className='buttonArrow'/></Button>
                        <Button className='marketingButtonCategory'>Party Supplies <ArrowForward className='buttonArrow'/></Button>
                        <Button className='marketingButtonCategory'>Tools <ArrowForward className='buttonArrow'/></Button>
                    </div>
                    <div className='fb se fw'>
                        <Button className='marketingButtonCategory'>Study Equipment <ArrowForward className='buttonArrow'/></Button>
                        <Button className='marketingButtonCategory'>College Gear <ArrowForward className='buttonArrow'/></Button>
                        <Button className='marketingButtonCategory'>Equipment <ArrowForward className='buttonArrow'/></Button>
                    </div>
                </div>
            </div>
            <div className='fb sb fw'>
                <img src="images/3961830.jpg" width="22%" height="165px" style={{minWidth:160}}/>
                <img src="images/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg" width="22%" height="165px" style={{minWidth:160}}/>
                <img src="images/IMG_0163.jpeg" width="22%" height="165px" style={{minWidth:160}}/>
                <img src="images/priscilla-du-preez-XkKCui44iM0-unsplash.jpg" width="22%" height="165px" style={{minWidth:160}}/>
            </div>
        </div>
    )

    
}