import { Button, TextField } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import * as XLSX from 'xlsx';
import firebase from 'firebase'
import { saveAs } from 'file-saver';
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";

export default function UrlUpload() {
    const [url, seturl ] = React.useState("")

	useEffect(() => {
		window.scrollTo(0, 0)
		}, [])
    function fetchUrlDetail(){
        var fetchUrlDetails = firebase.functions().httpsCallable('fetchUrlDetails');
        fetchUrlDetails({url: url}).then((result) => {
            console.log(result)
        })
    }
    
    return (
        <div style={{margin:'0 30%'}}>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <div style={{margin:20,fontSize:'large'}}>Upload A Rental by URL</div>
            <div style={{margin:20,fontSize:'large'}}>We will retrieve the data from your website and confrim the infromation here before uploading.</div>
            <hr/>
            <div style={{margin:10,fontSize:'small'}}>
                <TextField
                        placeholder="Url"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={url}
                        onChange={(val)=>{seturl(val.value)}}
                        variant="outlined"
                        />
            </div>
            <button class="btn btn--primary btn--medium null" onClick={()=>{fetchUrlDetail()}}>Retrieve</button>
            </div>
            <hr/>
            <div style={{marginBottom:20,marginTop:20,textAlign:'center'}}>
            <div style={{margin:20,fontSize:'large'}}>Details</div>
            <div style={{margin:10}}>All Fields are required to upload</div>
            <div style={{margin:10,fontSize:'small'}}>
                <TextField
                        placeholder="Rental Title"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={url}
                        onChange={(val)=>{seturl(val.value)}}
                        variant="outlined"
                        />
            </div>
            <button class="btn btn--primary btn--medium null">Complete Upload</button>
            </div>
        </div>
    )

}