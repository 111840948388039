import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    paper: {

    },
    control: {
        padding: theme.spacing(2),
    },
    title: {
        textAlign:'center',
        fontWeight: 'bold',
        marginBottom:'30px',
        marginTop:'30px'
    },
    containClass:{
        justifyContent: 'center'
    }
}));
const helpDataRenter = [
    {
        image : 'images/image002.jpg',
        link:'',
        key: 1
    },
    {
        image : 'images/Outlook-gnughwem.jpg',
        link:'',
        key: 2,
    }
];

export default function Partner() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
        <div className={classes.title}>Partners</div>
        <marquee className='marqueeback'>
        <div>
            <Grid item style={{display:'flex',justifyContent:'space-around'}}>
                <Grid container className={classes.containClass}>
                    {helpDataRenter.map((value) => {
                            return (
                                <Grid key={value.key} item>
                                    <Paper onClick={() =>{history.push("/apps")}}>
                                        <div style={{height:'100%'}}>
                                            <div style={{paddingTop:'3px',marginRight:50}}><img src ={value.image} height="170" width="400" /></div>
                                        </div>
                                    </Paper>
                                </Grid>
                                )
                        
                    })}
                </Grid>
            </Grid>
        </div>
        </marquee>
        </>
    )

}